<template>
  <v-container class="mt-5" style="height: 85vh;">
      <v-dialog v-model="showDialog">
      <v-card>
        <v-card-title>
          <span class="headline">Aufgabe erstellen</span>
        </v-card-title>
        <v-card-text>
          <v-container style="flex-wrap: wrap">
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  outlined
                  label="Titel"
                  v-model="title"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  id="mail"
                  outlined
                  label="Datum"
                  type="date"
                  v-model="Fällig"
                ></v-text-field>
              </v-col>
              
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  outlined
                  label="Bearbeiter"
                  :items="users"
                  v-model="SelectedUser"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Beschreibung der Aufgabe"
                  v-model="beschreibung"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray darken-1"
            text
            @click="showDialog = !showDialog"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="primary"
            @click="createTodo()"
          >
            Aufgabe erstellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-btn
        color="primary"
        elevation="24"
        absolute
        bottom
        fab
        >
        <v-icon @click="showDialog = !showDialog">mdi-plus</v-icon>
        </v-btn>
    <v-row justify="center" class="mx-2" cols= breakpoints>
    <v-row 
    @click= goToTask(todo._id)
    v-for="todo in todos"
     :key="todo._id"
     :class= todo.Status
     class="todo-item col-12">
        <v-col>
            <h4>{{ todo.Title }}</h4>
            <p>{{ todo.Description }}</p>
        </v-col>
        <v-col align="end">
            <span>{{ todo.DueDate }}</span><br>
            <strong>{{ todo.Assignee }}</strong>
        </v-col>
    </v-row>
  </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
    data: () => ({
        todos: [],
        comment: "",
        UserName: localStorage.getItem('Username'),
        showDialog: false,
        title: "",
        Fällig: "",
        beschreibung: "",
        users: [],
        SelectedUser: ""
    }),
    computed: {
        /* eslint-disable */ 
      breakpoints () { 
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 12
          case 'sm': return 12
          case 'md': return 6
          case 'lg': return 6
          case 'xl': return 6
        }
      },
    },
    methods: {
        fetchTodos: async function() {
            axios
            .get("https://kjg-api.rezept-zettel.de/api/todos", {
                headers: {
                    'auth-token': localStorage.getItem('Token')
                }
                })
            .then(Response => {
                this.todos = Response.data
            })
            .catch((err) => {
                console.log(err, err.stack)
                if(localStorage.getItem('RefreshToken') != undefined) {
                    axios
                    .post('https://kjg-api.rezept-zettel.de/api/auth/refresh-token/', {
                        refreshToken: localStorage.getItem("RefreshToken")
                    })
                    .then(Response => {
                        const data = Response.data
                        localStorage.setItem("Token", data.accessToken)
                        localStorage.setItem("RefreshToken", data.RefreshToken)
                        this.fetchTodos()
                    })
                }
            })
        },
        FetchUser: async function() {
            axios
            .get("https://kjg-api.rezept-zettel.de/api/auth")
            .then(Response => {
                let Arr = [];
                for(var i = 0; i < Response.data.length; i++) {
                    Arr.push(Response.data[i].name)
                }
                this.users = Arr
            })
            .catch((err) => {
                console.log(err, err.stack)
            })
        },
        createTodo() {
            axios
            .post('https://kjg-api.rezept-zettel.de/api/todos', {
                "Title": this.title,
                "Description": this.beschreibung,
                "Assignee": this.SelectedUser,
                "DueDate": this.Fällig
            })
            .then(() => {
                Vue.$toast.open({
                  message: 'Aufgabe wurde erstellt.',
                  type: 'success',
              });
              this.fetchTodos();
              this.showDialog = false;
            })
            .catch(() => {
                Vue.$toast.open({
                  message: 'Versuche es später erneut',
                  type: 'error',
              });
            })
        },
    goToTask(id) {
      this.$router.push('detail/' + id)
  }
    },
    created(){
        this.fetchTodos(),
        this.FetchUser();
    }
}
</script>

<style>
.todo-item {
    background-color: #efefef;
    border-radius: 5px;
    margin: 5px 0;
    max-height: 100px;
}

.Wird.bearbeitet {
    border-left: 5px solid #f07e14 !important
}

.Bereit.zum.bearbeiten {
    border-left: 5px solid #c73f3f !important
}

.Fertig {
    border-left: 5px solid #4fc73f !important
}

@media (max-width: 1227px) {
.todo-item {
    max-height: unset !important;
}
}
</style>